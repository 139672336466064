const inViewport = (el) => {
    if (!el || 1 !== el.nodeType) {
        return false;
    }

    const html = document.documentElement;
    const r = el.getBoundingClientRect();

    return !!r && r.bottom >= 0 && r.right >= 0 && r.top <= html.clientHeight && r.left <= html.clientWidth;
};

const loadSvgSprite = (spriteUrl) => {
    fetch(spriteUrl, {
        method: 'GET',
    })
        .then((response) => response.text())
        .then((data) => {
            const div = document.createElement('div');

            div.innerHTML = data;
            div.classList.add('svg-sprite');
            document.body.insertBefore(div, document.body.childNodes[0]);
        });
};

const getCookie = (name) => {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

const setCookie = (name = '', value = '', maxAge) => {
    let cookie;

    if (name.length && value.length) {
        cookie = `${name}=${encodeURIComponent(value)};path=/`;

        if (maxAge) {
            cookie = `${cookie};max-age=${maxAge}`;
        }
    }

    if (cookie && cookie.length) {
        document.cookie = cookie;
    }
};

export { inViewport, loadSvgSprite, getCookie, setCookie };
