import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';

Elemental.Components.SubNav = class SubNav extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                active: 'active',
            },
            selectors: {
                subnavLink: '.subnav-link',
            },
        };

        this.subnavLinkEls = this.element.querySelectorAll(this.css.selectors.subnavLink);
        this.activeSectionId = '';
    }

    setEventListeners() {
        if (this.element.dataset.inPageNav) {
            this.subnavLinkEls.forEach((linkEl) =>
                linkEl.addEventListener(events.subnav.itemInView, this.onInView.bind(this))
            );
        }
    }

    onInView(event) {
        const sectionId = event.target.href;

        if (this.activeSectionId !== sectionId) {
            this.subnavLinkEls.forEach((linkEl) => linkEl.classList.remove(this.css.classes.active));
            event.target.classList.add(this.css.classes.active);
            this.activeSectionId = sectionId;
        }
    }

    render() {
        this.setVars();
        this.setEventListeners();
    }
};
