export default {
    carousel: {
        resetBreakpoints: 'pulse:carousel:resetBreakpoints',
    },
    forms: {
        error: '11fs:forms:error',
        resetForm: '11fs:forms:resetForm',
        resetValidation: '11fs:forms:resetValidation',
        submitted: '11fs:forms:submitted',
    },
    search: {
        search: '11fs:search:search',
        results: '11fs:search:results',
        noResults: '11fs:search:noResults',
        clear: '11fs:search:clear',
    },
    subnav: {
        itemInView: '11fs:subnav:itemInView',
        scrollToItem: '11fs:subnav:scrollToItem',
    },
    images: {
        lazyLoaded: '11fs:images:lazyLoaded',
    },
};
