import Elemental from 'jslib/elemental/elemental';
import cookiesPopupTemplate from '../templates/cookies-popup';

Elemental.Components.CookiesPopup = class CookiesPopup extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                slideUp: 'slide-up',
            },
            selectors: {
                dismissButton: '.cookies-popup__dismiss',
            },
        };

        this.storage = {
            cookiesAccepted: 'cookiesAccepted',
        };

        this.accepted = window.localStorage.getItem(this.storage.cookiesAccepted);
    }

    setEventListeners() {
        this.element.addEventListener('click', this.onClick.bind(this));
    }

    onClick(event) {
        // TODO check if this needs more work
        if (event.target.closest(this.css.selectors.dismissButton)) {
            this.dismissPopup();
        }
    }

    renderPopup() {
        const data = ELEVEN.cookies;

        data.text = unescape(data.text);

        const htmlString = cookiesPopupTemplate(data);
        const htmlEl = document.createRange().createContextualFragment(htmlString);

        this.element.appendChild(htmlEl);
    }

    dismissPopup() {
        const popupHeight = this.element.getBoundingClientRect().height;

        window.localStorage.setItem(this.storage.cookiesAccepted, '1');
        this.element.classList.add(this.css.classes.slideUp);
        this.bodyEl.style.transform = `translateY(-${popupHeight}px)`;
    }

    init() {
        if (!this.accepted) {
            this.renderPopup();
        }
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
};
