import Elemental from 'jslib/elemental/elemental';
import FormValidator from 'jslib/form-validator/form-validator';

Elemental.Components.Form = class Form extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            selectors: {
                errors: 'ul.errors',
            },
        };

        this.submitButtonEl = this.element.querySelector('button[type=submit]');
        this.submitButtonText = this.submitButtonEl ? this.submitButtonEl.innerText : 'Submit';
        this.showLoadingState = true;

        if (this.element.dataset.showLoadingState) {
            this.showLoadingState = JSON.parse(this.element.dataset.showLoadingState);
        }
    }

    setEventListeners() {
        this.element.addEventListener('submit', this.onSubmit.bind(this));
    }

    onSubmit(event) {
        const isFormValid = this.isFormValid();

        if (!isFormValid) {
            event.preventDefault();
        }

        this.setSubmitButtonDisabled(isFormValid);
    }

    isFormValid() {
        return this.validator.validateForm();
    }

    resetValidation() {
        const errorsEl = this.element.querySelector(this.css.selectors.errors);

        if (errorsEl) {
            errorsEl.remove();
        }

        this.validator.removeAllErrors();
    }

    setSubmitButtonDisabled(disabled) {
        if (!this.showLoadingState) {
            return;
        }

        if (this.submitButtonEl) {
            this.submitButtonEl.disabled = disabled;
        }
    }

    init() {
        this.validator = new FormValidator(this.element);
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
};
