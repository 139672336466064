import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';
import { Dropdown as BootstrapDropdown, Collapse as BootstrapCollapse } from 'bootstrap';

Elemental.Components.Navigation = class Navigation extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                show: 'show',
                dropdownOpen: 'dropdown-open',
                navOpen: 'nav-open',
                subnavOpen: 'subnav-open',
            },
            selectors: {
                navItem: '.nav-item',
                navbarToggle: '.navbar-toggle',
                dropdownMenu: '.dropdown-menu',
                dropdownBackButton: '.dropdown-menu__header__back',
                dropdownCollapse: '.c01__dropdown',
                subnavToggle: '.subnav-toggle',
                subnavWrapper: '.c01__subnav__wrapper',
            },
        };

        this.navItemEls = this.element.querySelectorAll(this.css.selectors.navItem);
        this.dropdownBackButtonEls = this.element.querySelectorAll(this.css.selectors.dropdownBackButton);
        this.navbarToggleEl = this.element.querySelector(this.css.selectors.navbarToggle);
        this.subnavToggleEls = this.element.querySelectorAll(this.css.selectors.subnavToggle);
        this.dropdownCollapseEls = this.element.querySelectorAll(this.css.selectors.dropdownCollapse);
        this.subnavWrapperEl = this.element.querySelector(this.css.selectors.subnavWrapper);

        this.navbarShow = false;
        this.subnavShow = false;
    }

    setEventListeners() {
        this.navItemEls.forEach((itemEl) => {
            itemEl.addEventListener('show.bs.dropdown', this.onShowDropdown.bind(this));
            itemEl.addEventListener('hide.bs.dropdown', this.onHideDropdown.bind(this));
            itemEl.addEventListener('shown.bs.dropdown', this.onShownDropdown.bind(this));
        });
        this.dropdownBackButtonEls.forEach((buttonEl) =>
            buttonEl.addEventListener('click', this.onDropdownBackClick.bind(this))
        );
        this.navbarToggleEl.addEventListener('click', this.onNavbarToggle.bind(this));
        this.subnavToggleEls.forEach((toggleEl) => toggleEl.addEventListener('click', this.onSubnavToggle.bind(this)));
        this.dropdownCollapseEls.forEach((collapseEl) => {
            collapseEl.addEventListener('show.bs.collapse', this.onShowCollapse.bind(this));
            collapseEl.addEventListener('hide.bs.collapse', this.onHideCollapse.bind(this));
        });
    }

    onNavbarToggle(event) {
        event.preventDefault();

        this.toggleNavbar(event.currentTarget.dataset.target);
    }

    onSubnavToggle(event) {
        this.toggleSubnav(event);
    }

    onShowDropdown(event) {
        this.bodyEl.classList.add(this.css.classes.dropdownOpen);
    }

    onHideDropdown(event) {
        this.bodyEl.classList.remove(this.css.classes.dropdownOpen);

        this.clearDropdownPosition();
    }

    onShownDropdown(event) {
        if (window.matchMedia('(min-width: 992px)').matches) {
            this.setDropdownPosition();
        }
    }

    onDropdownBackClick(event) {
        const targetEl = event.currentTarget.closest('[data-bs-toggle=dropdown]');
        const dropdown = BootstrapDropdown.getOrCreateInstance(targetEl);

        dropdown.hide();
    }

    onShowCollapse(event) {
        this.dropdownCollapseEls.forEach((collapseEl) => {
            if (collapseEl.id !== event.target.id) {
                const collapse = BootstrapCollapse.getInstance(collapseEl);

                if (collapse) {
                    collapse.hide();
                }
            }
        });
    }

    onHideCollapse(event) {
        if (event.target.id === 'searchDropdown') {
            event.target
                .querySelector('[data-efs-component="search"]')
                .dispatchEvent(new CustomEvent(events.search.clear));
        }
    }

    setDropdownPosition() {
        const dropdownEl = this.element.querySelector(`${this.css.selectors.dropdownMenu}.${this.css.classes.show}`);
        const viewportWidth = document.documentElement.clientWidth;
        const leftPos = Math.floor(dropdownEl.getBoundingClientRect().left);
        const rightPos = Math.floor(dropdownEl.getBoundingClientRect().right);
        let leftStyle;

        // Check if dropdown has overflowed out of the viewport and correct dropdown positioning if so
        if (rightPos > viewportWidth) {
            leftStyle = `calc(50% - ${rightPos - viewportWidth}px)`;
        } else if (leftPos < 0) {
            leftStyle = `calc(50% + ${leftPos * -1}px)`;
        }

        dropdownEl.style.left = leftStyle;
    }

    clearDropdownPosition() {
        const dropdownEl = this.element.querySelector(`${this.css.selectors.dropdownMenu}.${this.css.classes.show}`);

        dropdownEl.style.left = '';
    }

    toggleNavbar(target) {
        const navbarEl = document.getElementById(target.split('#')[1]);

        this.navbarShow = !this.navbarShow;

        navbarEl.classList.toggle(this.css.classes.show);
        this.element.classList.toggle(this.css.classes.navOpen);
        this.navbarToggleEl.setAttribute('aria-expanded', this.navbarShow);
    }

    toggleSubnav(event) {
        const expanded = !JSON.parse(event.currentTarget.getAttribute('aria-expanded'));

        this.subnavWrapperEl.classList.toggle(this.css.classes.show, expanded);
        this.bodyEl.classList.toggle(this.css.classes.subnavOpen, expanded);

        // Make sure all subnav toggles have the correct aria-expanded state
        this.subnavToggleEls.forEach((toggleEl) => toggleEl.setAttribute('aria-expanded', expanded));
    }

    render() {
        this.setVars();
        this.setEventListeners();
    }
};
