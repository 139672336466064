import '../scss/main.scss';

import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';
import { loadSvgSprite } from 'jslib/utils.js';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';

import '../../components/form/js/form';
import '../../components/carousel/js/carousel';
import '../../components/cookies-popup/js/cookies-popup';
import '../../components/subnav/js/subnav';
import '../../components/search/js/search';
import '../../components/c01-navigation/js/c01-navigation';
import '../../components/c31-social-buttons/js/c31-social-buttons';
import '../../components/c44-audio-player/js/c44-audio-player';

Elemental.init({
    componentSelector: '[data-efs-component]',
    window: window,
    globalObject: ELEVEN,
});

loadSvgSprite(ELEVEN.assets.svg);

ELEVEN.lazyLoad = new LazyLoad({
    elements_selector: '.lazy-load',
    callback_loaded: (element) => element.dispatchEvent(new CustomEvent(events.images.lazyLoaded)),
});
