import Elemental from 'jslib/elemental/elemental';
import Plyr from 'plyr';

Elemental.Components.AudioPlayer = class AudioPlayer extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            selectors: {
                audio: '.c44__audio-player audio',
            },
        };

        this.audioEl = this.element.querySelector(this.css.selectors.audio);
        this.startTime = this.element.dataset.startTime;
        this.playbackStarted = false;
    }

    init() {
        if (this.audioEl) {
            this.player = new Plyr(this.audioEl, {
                controls: ['play', 'progress', 'current-time', 'mute'],
            });

            this.player.on('playing', () => {
                if (this.startTime && !this.playbackStarted) {
                    // Only set start time once
                    this.player.currentTime = parseInt(this.startTime);
                    this.playbackStarted = true;
                }
            });
        }
    }

    render() {
        this.setVars();
        this.init();
    }
};
