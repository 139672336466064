import Elemental from 'jslib/elemental/elemental';

Elemental.Components.SocialButtons = class SocialButtons extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setEventListeners() {
        this.element
            .querySelectorAll('a')
            .forEach((linkEl) => linkEl.addEventListener('click', this.onLinkClick.bind(this)));
    }

    onLinkClick(event) {
        const url = event.currentTarget.href;
        const title = event.currentTarget.title;
        const width = 580;
        const height = 470;
        const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
        const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;

        event.preventDefault();

        return window.open(
            url,
            title,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
        );
    }

    render() {
        this.setEventListeners();
    }
};
