function getCookiesPopup(data) {
    return `<div class="cookies-popup__inner">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 d-flex justify-content-between align-items-center">
                    ${data.text}

                    <button type="button" class="btn btn-primary cookies-popup__dismiss">
                        ${data.ctaLabel}
                    </button>
                </div>
            </div>
        </div>
    </div>`;
}

function compile(data) {
    return getCookiesPopup(data);
}

export default compile;
