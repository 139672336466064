import Elemental from 'jslib/elemental/elemental';
import SearchManager from 'jslib/search-manager/search-manager';
import events from 'jslib/custom-events';
import debounce from 'lodash/debounce';
import keycodes from 'keycodes';

Elemental.Components.Search = class Search extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                active: 'active',
                hasResults: 'has-results',
            },
            selectors: {
                searchInput: '.form-control',
                articleListingMore: '.article-listing__more',
            },
        };

        const defaultSearchConfig = {
            colorTheme: 'light',
            excludeIds: [],
            filters: null,
            headingTag: 'h3',
            layoutCardLimit: 5,
            layoutIndex: 0,
            minQueryLength: 2,
            resultsPage: 0,
            resultsType: 'page',
        };

        this.searchConfig = Object.assign(defaultSearchConfig, this.getSearchConfig());
        this.page = this.searchConfig.resultsPage;
        this.searchPageUrl = this.element.dataset.searchPageUrl;
        this.templates = {};

        this.searchInputEl = this.element.querySelector(this.css.selectors.searchInput);
        this.resultsContainerEl = document.getElementById(this.searchConfig.resultsContainerId);
        // this.$clearButton = this.$element.find(this.css.selectors.clearButton);
    }

    setEventListeners() {
        this.element.addEventListener('submit', this.onSearchFormSubmit.bind(this));

        if (this.searchInputEl) {
            this.searchInputEl.addEventListener('keyup', debounce(this.onSearchInputKeyUp.bind(this), 300));
        }

        this.resultsContainerEl.addEventListener('click', this.onResultsContainerClick.bind(this));
        this.element.addEventListener(events.search.results, this.onSearchResult.bind(this));
        this.element.addEventListener(events.search.clear, this.onClear.bind(this));
    }

    onResultsContainerClick(event) {
        if (event.target.matches(`${this.css.selectors.articleListingMore} .btn`)) {
            this.onViewMoreClick(event);
        }
    }

    onSearchInputKeyUp(event) {
        const query = this.searchInputEl.value.trim();
        const ignore = ['left', 'right', 'up', 'down'];

        if (keycodes(event.which) === 'esc') {
            this.searchInputEl.blur();

            return;
        }

        if (!ignore.includes(keycodes(event.which))) {
            if (query && query.length) {
                // this.showSearchInputClearButton();
                this.searchManager.clearResults();
                this.setSearchQuery(query);
            } else {
                // this.hideSearchInputClearButton();
                this.clearSearchResults();
            }
        }
    }

    onSearchFormSubmit(event) {
        event.preventDefault();

        this.goToSearchResultsPage();
    }

    onSearchResult(event) {
        const data = event.detail;

        if (this.searchPageUrl && this.searchPageUrl.length) {
            data.resultsUrl = this.searchPageUrl;
        }

        this.renderResults(data);
    }

    onViewMoreClick(event) {
        this.page++;

        this.searchManager.setPage(this.page, this.searchConfig.contentIndex);
        event.currentTarget.blur();
    }

    onClear() {
        this.clearSearchInput();
        this.clearSearchResults();
    }

    getSearchConfig() {
        const configEl = document.getElementById(this.element.dataset.searchConfigId);
        let config;

        if (configEl) {
            config = configEl.innerText;

            try {
                config = JSON.parse(config);
            } catch (error) {
                // TODO - replace with Sentry logging etc.
                return;
            }
        }

        return config;
    }

    renderResults(data) {
        const indexName = this.searchManager.getCleanIndexName(this.searchConfig.contentIndex);
        const resultsHtml = this.templates.results(data);
        const moreButtonEl = this.resultsContainerEl.querySelector(this.css.selectors.articleListingMore);
        const totalPages = data[indexName].nbPages;
        const currentPage = data[indexName].page + 1; // zero-based

        if (this.page > 0) {
            const htmlEl = document.createRange().createContextualFragment(resultsHtml);

            moreButtonEl.parentNode.insertBefore(htmlEl, moreButtonEl);
        } else {
            this.resultsContainerEl.innerHTML = resultsHtml;
        }

        if (currentPage === totalPages && moreButtonEl) {
            moreButtonEl.remove();
        }

        this.element.classList.add(this.css.classes.hasResults);
        Elemental.renderComponents(this.resultsContainerEl);
        this.globalObject.lazyLoad.update();
    }

    getIndices() {
        return this.searchConfig.indices.split(',');
    }

    setSearchQuery(query) {
        this.query = query;
        this.searchManager.setSearchQuery(query);

        if (query.length >= this.searchConfig.minQueryLength) {
            // this.showSearchInputClearButton();
            this.updateUrlQueryString(query);
            this.clearSearchResults();
        }
    }

    clearSearchInput() {
        this.searchInputEl.value = '';
        this.searchInputEl.focus();
        // this.$clearButton.removeClass(this.css.classes.active);
    }

    clearSearchResults() {
        this.resultsContainerEl.innerHTML = '';
        this.element.classList.remove(this.css.classes.hasResults);
    }

    updateUrlQueryString(query) {
        const hasSearch = window.location.search.includes('query');
        const url = `${window.location.protocol}//${window.location.host}${
            window.location.pathname
        }?query=${query.toLowerCase()}`;

        if (history.pushState && hasSearch) {
            window.history.pushState({ path: url }, '', url);
        }
    }

    goToSearchResultsPage() {
        if (this.query && this.query.length >= this.searchConfig.minQueryLength && this.searchPageUrl) {
            window.location.assign(`${this.searchPageUrl}?query=${this.query}`);
        }
    }

    async importResultTemplate(resultsTemplate) {
        const template = await import(`../templates/${resultsTemplate}.js`);

        return template.default;
    }

    init() {
        this.importResultTemplate(this.searchConfig.resultsTemplate).then((template) => {
            const searchManagerConfig = Object.assign(
                {
                    globalObject: this.globalObject,
                    resultLimits: {
                        content: this.searchConfig.resultsLimit,
                    },
                    searchElement: this.element,
                },
                this.searchConfig
            );

            this.templates.results = template;

            // Set up Search Manager
            this.searchManager = new SearchManager(searchManagerConfig);

            if (this.searchInputEl) {
                const query = this.searchInputEl.value.trim();

                if (query.length > this.searchConfig.minQueryLength) {
                    this.setSearchQuery(query);
                }
            }
        });
    }

    render() {
        this.setVars();
        this.init();
        this.setEventListeners();
    }
};
