import events from 'jslib/custom-events';
import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';

class SearchManager {
    constructor(options) {
        this.defaults = {
            minQueryLength: 2,
            layoutCardLimit: 5,
            layoutIndex: 0,
            indices: [],
            contentIndex: 'content',
            resultLimits: {
                content: 4,
                subjects: 4,
                industries: 4,
                disciplines: 4,
                tags: 8,
                taxonomy: 8,
            },
            resultsType: 'page',
            resultsLayoutConfig: [
                {
                    cardSizes: ['lg', 'sm', 'md', 'xs', 'xs'],
                    leftColCardLimit: 2,
                    columnClasses: ['col-md-6', 'col-md-5 offset-md-1'],
                },
                {
                    cardSizes: ['md', 'xs', 'xs', 'lg', 'sm'],
                    leftColCardLimit: 3,
                    columnClasses: ['col-md-5', 'col-md-6 offset-md-1'],
                },
            ],
            colorTheme: 'light',
            headingTag: 'h3',
        };

        this.algoliaHelpers = {};
        this.searchParams = {};
        this.results = {};
        this.searchActive = false;

        this.options = Object.assign({}, this.defaults, options);

        // Object.assign does a shallow merge, so we need to manually merge resultsLimits
        // to allow overriding of just a single property in the resultsLimits object
        this.options.resultLimits = Object.assign({}, this.defaults.resultLimits, options.resultLimits);

        this.searchParams[`${this.options.contentIndex}`] = {};

        if (this.options.filters) {
            this.searchParams[`${this.options.contentIndex}`] = {
                facets: [],
                disjunctiveFacets: Object.keys(this.options.filters),
            };
        }

        if (this.options.excludeIds) {
            const filters = this.options.excludeIds.map((id) => `id != ${id}`);

            this.searchParams[`${this.options.contentIndex}`].filters = filters.join(' AND ');
        }

        if (this.options.indices.length) {
            this.initAlgoliaHelpers(this.options.indices);
            this.layoutIndex = this.options.layoutIndex;
        }
    }

    onSearchResult(resultSet) {
        this.addResultSetToResults(resultSet.results);
        const fullResults = this.getResults();

        if (Object.keys(fullResults).length === this.options.indices.length) {
            this.processSearchResults(fullResults);
        }
    }

    addResultSetToResults(resultSet) {
        const indexName = this.getCleanIndexName(resultSet.index);

        if (indexName && indexName.length) {
            this.results[indexName] = resultSet;
        }
    }

    processSearchResults(results) {
        let data = {};
        let totalResults = 0;

        Object.keys(results).forEach((key) => {
            const resultSet = results[key];
            const indexName = this.getCleanIndexName(resultSet.index);

            try {
                data[indexName] = this.getFormattedResultData(resultSet);
            } catch(exception) {
                /* eslint-disable-next-line no-console */
                console.log({ exception });
            }
        });

        data = this.removeTaxonomyDuplicates(data);

        totalResults = this.getTotalResultsCount(data);
        data = this.getConsolidatedResultData(data);

        if (totalResults > 0) {
            this.emitEvent(this.options.searchElement, events.search.results, data);
        } else {
            this.emitEvent(this.options.searchElement, events.search.noResults);
        }
    }

    getFormattedResultData(resultSet) {
        const indexName = this.getCleanIndexName(resultSet.index);
        let data = {};

        switch (indexName) {
            case 'content':
                data = this.formatContentResultData(resultSet);
                break;

            case 'disciplines':
            case 'industries':
            case 'subjects':
            case 'tags':
                data = this.formatTaxonomyResultsData(resultSet);
                break;
        }

        return data;
    }

    getConsolidatedResultData(data) {
        const taxonomy = {
            hits: [],
        };

        data = this.getReducedTaxonomyData(data);

        for (const value of Object.values(data)) {
            value.hits.forEach((hit) => {
                hit.variant = this.options.colorTheme;

                if (hit.taxonomy) {
                    taxonomy.hits.push(hit);
                }
            });
        }

        data.taxonomy = taxonomy;

        return data;
    }

    getReducedTaxonomyData(data) {
        let higherTaxonomyTotal = 0;
        let remainingSlots = 0;

        // Calculate how many higher-order taxonomy items (subjects, industries etc.) we have
        for (const [key, value] of Object.entries(data)) {
            if (key !== 'tags') {
                higherTaxonomyTotal += value.hits.filter((hit) => hit.taxonomy).length;
            }
        }

        if (higherTaxonomyTotal > 0) {
            remainingSlots = this.options.resultLimits['taxonomy'] - higherTaxonomyTotal;

            // If there are slots left in the taxonomy
            if (remainingSlots > 0) {
                // Reduce the tag hits to only enough to fill the remaining slots
                data.tags.hits = data.tags.hits.slice(0, remainingSlots);
            }
        }

        return data;
    }

    getTotalResultsCount(results) {
        let totalResults = 0;

        Object.keys(results).forEach((key) => {
            totalResults += results[key].hits.length;
        });

        return totalResults;
    }

    formatContentResultData(data) {
        if (this.options.resultsType == 'page') {
            data.hits = data.hits.map((hit, index) => {
                return this.getArticleListingCardData(hit, index, data.hits.length);
            });
        } else {
            data.hits = data.hits.map((hit) => {
                const articleData = this.getArticleData(hit);
                const cardSize = 'xs';

                return {
                    ...hit,
                    ...articleData,
                    showIntro: false,
                    showImage: true,
                    cardSize: cardSize,
                    imageSizes: hit.thumbnail[cardSize],
                };
            });
        }

        if (data.nbPages > 1 && data.page < data.nbPages) {
            data.pagination = true;
        }

        return data;
    }

    formatTaxonomyResultsData(data) {
        return data;
    }

    removeTaxonomyDuplicates(data) {
        if (data.tags) {
            // Search for tags that match items that are further up in our taxonomy
            // like Subjects etc. and remove from tags results
            data.tags.hits = data.tags.hits.filter((tag) => {
                const existingSubject = data.subjects.hits.filter((subject) => subject.textTitle === tag.textTitle);
                const existingDiscipline = data.disciplines.hits.filter(
                    (discipline) => discipline.textTitle === tag.textTitle
                );
                const existingIndustry = data.industries.hits.filter(
                    (industry) => industry.textTitle === tag.textTitle
                );
                let exists = false;

                if (existingSubject.length || existingDiscipline.length || existingIndustry.length) {
                    exists = true;
                }

                return !exists;
            });
        }

        return data;
    }

    getArticleListingCardData(hit, index, total) {
        let layoutIndex = this.layoutIndex;

        if (index % this.options.layoutCardLimit === 0 && index > 0) {
            layoutIndex = layoutIndex === 0 ? 1 : 0;
            this.layoutIndex = layoutIndex;
        }

        const layoutConfig = this.options.resultsLayoutConfig;
        const cardSize = layoutConfig[layoutIndex].cardSizes[index % this.options.layoutCardLimit];
        const showImage = true;
        const articleData = this.getArticleData(hit);
        let startRow = false;
        let showIntro = false;
        let endCol = false;
        let endRow = false;
        let columnClass = null;
        let icon = null;
        let categoryLink = null;

        if (cardSize === 'lg' || cardSize === 'md') {
            showIntro = true;
        }

        if (hit.type === 'video') {
            icon = 'play';
        }

        if (hit.type === 'podcast') {
            categoryLink = hit?.show?.url ?? '';

            if (categoryLink === '') {
                /* eslint-disable-next-line no-console */
                console.log(hit);
            }
        }

        if (index % this.options.layoutCardLimit === 0) {
            startRow = true;
            columnClass = layoutConfig[layoutIndex].columnClasses[0];
        }

        if ((index + 1) % this.options.layoutCardLimit === layoutConfig[layoutIndex].leftColCardLimit) {
            endCol = true;
            columnClass = layoutConfig[layoutIndex].columnClasses[1];
        }

        if (index + 1 === total || ((index + 1) % this.options.layoutCardLimit === 0 && index > 0)) {
            endRow = true;
        }

        return {
            ...hit,
            ...articleData,
            cardSize: cardSize,
            startRow: startRow,
            endRow: endRow,
            endCol: endCol,
            columnClass: columnClass,
            showIntro: showIntro,
            showImage: showImage,
            imageSizes: hit.thumbnail[cardSize],
            icon: icon,
            categoryLink: categoryLink,
        };
    }

    getArticleData(hit) {
        const data = {
            title: hit._highlightResult.title.value,
            intro: this.getIntroText(hit.intro),
            number: null,
            numberCssClass: null,
            variant: this.options.colorTheme,
            headingTag: this.options.headingTag,
            consumptionTime: this.getConsumptionTime(hit),
            consumptionLabel: this.getConsumptionLabel(hit),
        };
        const titleParts = data.title.split('.');

        if (hit.type === 'podcast' && titleParts.length > 1) {
            data.title = titleParts[1].trim();
            data.number = titleParts[0].replaceAll(/<[^>]*>/gi, '');
            data.industries = [
                {
                    title: hit.show ? hit.show.title : (data.industries ? data.industries[0].title : ''),
                },
            ];
        }

        if (hit.show) {
            switch (hit.show.title) {
                case 'Fintech Insider':
                    data.numberCssClass = 'mint';
                    break;
                case 'Insurtech Insider':
                    data.numberCssClass = 'purple';
                    break;
                case 'Blockchain Insider':
                    data.numberCssClass = 'blue';
                    break;
            }
        }

        return data;
    }

    getIntroText(text) {
        const limit = 160;
        let intro = text || '';

        if (intro.length > limit) {
            intro = intro.slice(0, limit) + '...';
        }

        return intro;
    }

    getConsumptionTime(result) {
        let time = '';

        switch (result.type) {
            case 'podcast':
            case 'video':
                time = result.consumptionTime.split(':');

                if (time.length > 2) {
                    time = `${time[0]}h${time[1]}`;
                } else {
                    time = `${time[0]}min`;
                }

                break;
            default:
                time = `${result.consumptionTime}min`;
        }

        return time;
    }

    getConsumptionLabel(result) {
        let label = '';

        switch (result.type) {
            case 'podcast':
                label = 'listen';
                break;
            case 'video':
                label = 'watch';
                break;
            default:
                label = 'read';
        }

        return label;
    }

    getCleanIndexName(indexName) {
        if (indexName) {
            return indexName.split('_')[0];
        }

        return;
    }

    setSearchQuery(query) {
        if (query.length >= this.options.minQueryLength) {
            this.sendSearchQuery(query);
        }
    }

    sendSearchQuery(query) {
        this.options.indices.forEach((index) => {
            this.algoliaHelpers[index].setQuery(query).search();
        });

        this.emitEvent(this.options.searchElement, events.search.search, { query });
    }

    setPage(page, index) {
        this.algoliaHelpers[index].setPage(page).search();
    }

    setResults(results) {
        this.results = results;
    }

    getResults() {
        return this.results;
    }

    clearResults() {
        this.results = {};
    }

    emitEvent(targetElement, eventName, eventDetails = {}) {
        if (typeof window.CustomEvent !== 'function' || !targetElement) return;

        const event = new CustomEvent(eventName, {
            bubbles: true,
            detail: eventDetails,
        });

        targetElement.dispatchEvent(event);
    }

    initFacetRefinements(index) {
        if (this.options.filters) {
            for (const [facetName, facetValues] of Object.entries(this.options.filters)) {
                facetValues.forEach((value) => {
                    // Add facet refinements
                    this.algoliaHelpers[index].addDisjunctiveFacetRefinement(facetName, value);
                });
            }
        }
    }

    initAlgoliaHelpers(indices) {
        const algolia = algoliasearch(
            this.options.globalObject.algolia.applicationId,
            this.options.globalObject.algolia.searchApiKey
        );

        indices.forEach((index) => {
            if (index && index.length) {
                const indexName = this.getCleanIndexName(index);
                let searchParams = {
                    hitsPerPage: this.options.resultLimits[indexName],
                };

                if (index === this.options.contentIndex) {
                    searchParams = Object.assign({}, searchParams, this.searchParams[`${this.options.contentIndex}`]);
                }

                this.algoliaHelpers[index] = algoliasearchHelper(algolia, index, searchParams);
                this.algoliaHelpers[index].on('result', this.onSearchResult.bind(this));
                this.initFacetRefinements(index);
            }
        });
    }
}

export default SearchManager;
